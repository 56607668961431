import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import baseServices from './baseServices';
import httpHelper from '../helpers/httpHelper';

export default {
  createConsent: async (body) => {
    try {
      const url = api.ztl.createConsent;
      const data = await baseServices.postDataToken(url, body);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchConsentError: async (consentId, params = {}) => {
    try {
      const queryParams = httpHelper.serialize(params);
      let url = api.ztl.fetchConsentError.replace(':consentId', consentId)
      if (queryParams) {
        url = url + '?' + queryParams;
      }
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  scaSuccess: async (body) => {
    try {
      const url = api.ztl.scaSuccess;
      const data = await baseServices.putDataToken(url, body);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchConsentByCompany: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = api.ztl.fetchConsentByCompany + '?' + queryParams;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchBankAccountsByConsent: async ({ consentId, params = {} }) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = api.ztl.fetchAccountsByConsent.replace(':consentId', consentId) + '?' + queryParams;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  renewConsent: async ({ consentId, callbackUrl, accountOwnerSsn, debtorBankBic }) => {
    try {
      const url = api.ztl.renewConsent.replace(':consentId', consentId);
      const data = await baseServices.putDataToken(url, { callbackUrl, accountOwnerSsn, debtorBankBic });
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getAllBankTemplate: async ({ companyId }) => {
    try {
      const url = api.ztl.getAllBankTemplate.replace(':companyId', companyId);
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getConsentHistory: async (consentId, params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = api.ztl.getConsentHistory.replace(':consentId', consentId) + '?' + queryParams;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getStatusOnboarding: async (companyId) => {
    try {
      const url = api.ztl.baseOnboarding.replace(':companyId', companyId);
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  startOnboarding: async (companyId, params) => {
    try {
      const url = api.ztl.baseOnboarding.replace(':companyId', companyId);
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  callbackUpdateStatusOnboarding: async (companyId) => {
    try {
      const url = api.ztl.updateStatusByFlowId.replace(':companyId', companyId);
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getZtlAccountListByConsent: async (consentId) => {
    try {
      const url = api.ztl.getZtlAccountList + '?consentId=' + consentId;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getZtlCompanyNotification: async (params = {}) => {
    try {
      const { companyId, ...newQuery } = params;
      const queryParams = httpHelper.serialize(newQuery);
      const url = api.ztl.getZtlNotificationByCompany.replace(':companyId', companyId) + '?' + queryParams;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  readZtlNotification: async (params = {}) => {
    try {
      const { id } = params;
      const url = api.ztl.readZtlNotification.replace(':id', id);
      const data = await baseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  markAllZtlNotificationAsRead: async (params = {}) => {
    try {
      const { companyId } = params;
      const url = api.ztl.markZtlNotificationAsRead.replace(':companyId', companyId);
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  availableDueDates: async (params) => {
    try {
      const { countryCode } = params;
      const url = api.ztl.getAvailableDueDates.replace(':initiatingCountry', countryCode);
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getSupportedCurrencies: async () => {
    try {
      const url = api.ztl.getSupportedCurrencies;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getSupportedCountries: async () => {
    try {
      const url = api.ztl.getSupportedCountries;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getSupportedCurrencyByCountry: async (countryCode) => {
    try {
      const url = api.ztl.getSupportedCurrencyByCountry.replace(':countryCode', countryCode);
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
