import CrytoJs from 'crypto-js';
import moment from 'moment';
import queryString from 'query-string';
import * as ibantools from 'ibantools';
import react, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import * as ServerConfigs from '../configs/server.configs';
import currencyHelper from '../helpers/currencyHelper';
import timeHelper from '../helpers/timeHelper';

const setCookie = (name, value, minutes = 30) => {
  const date = new Date(Date.now() + minutes * 60 * 1000);
  const expires = `expires= ${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires}`;
};

const deleteCookie = (name) => {
  setCookie(name, '', -1);
};

const getCookie = (name) => {
  // eslint-disable-next-line no-param-reassign
  name = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setLocalStorage = (name, value) => {
  window.localStorage.setItem(name, value);
};

const getLocalStorage = (name) => {
  return window.localStorage.getItem(name);
};

const deleteLocalStorage = (name) => {
  window.localStorage.removeItem(name);
};

const loadNewBody = (newClass) => {
  if (newClass.length > 0 && Array.isArray(newClass)) {
    document.body.classList.remove('2-columns');
    newClass.forEach((className) => {
      document.body.classList.add(className);
    });
  }
};

const revertBody = (prevClass) => {
  if (prevClass.length > 0 && Array.isArray(prevClass)) {
    document.body.classList.add('2-columns');
    prevClass.forEach((className) => {
      document.body.classList.remove(className);
    });
  }
};

const checkCompanyExist = (companyList, company) => {
  let isExist = false;
  if (companyList.length) {
    isExist = companyList.some((x) => {
      return x.Alias === company;
    });
  }
  return isExist;
};

const getCurrentCompany = (props) => {
  // required withRouter;
  const { match } = props;
  return match.params.company;
};

export const getSelectCompany = (companyList, companyId) => {
  // required withRouter;
  const company = companyList.find((x) => companyId === x.CompanyId);
  return company;
};

const renderHTML = (rawHTML, tag = 'div') => react.createElement(tag, { dangerouslySetInnerHTML: { __html: rawHTML } });

const isHtml = (str) => {
  var doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

const formatCommon = {
  datePlus: 'YYYY-MM-DD',
  dateDot: 'DD.MM.YYYY',
  dateText: 'MMMM Do, YYYY',
};

const formatDatabase = (time) => moment(time).format(formatCommon.datePlus);

const formatView = (time) => moment(time).format(formatCommon.dateText);

const bankNumber = (value) => {
  const onlyNums = value ? value.replace(/[^\d]/g, '') : '';
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 4)}.${onlyNums.slice(4)}`;
  }
  return `${onlyNums.slice(0, 4)}.${onlyNums.slice(4, 6)}.${onlyNums.slice(6, 11)}`;
};

const dateFormat = (date) => {
  return date.replace(/^([\d]{2})([\d]{2})([\d]{3})$/, '$1/$2/$3');
};

const capitalize = (str) => {
  // capitalize first letter
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

const capitalizeSpace = (str) => {
  // MySite = My Site
  return str.replace(/([A-Z])/g, ' $1').trim();
};

// get query string to object
const getJsonFromUrl = (url) => {
  if (!url) url = window.location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const formatString = (string) => {
  let str = string.toString().trim().toLowerCase();
  const from = 'åãàáäâẽèéëêìíïîõòóöôùúüûñçø·/_,:;';
  const to = 'aaaaaaeeeeeiiiiooooouuuunco------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  return str;
};

export const getTimeZone = () => {
  const {
    location: { hostname },
  } = window;
  const localHosts = ['localhost', 'dev.bilagsky.no', 'www.dev.bilagsky.no'];
  const local = localHosts.includes(hostname);
  const timezone = local ? 'Asia/Singapore' : 'Europe/Amsterdam';
  return timezone;
};

export const slugify = (string) => {
  let str = string.toString().trim().toLowerCase();
  const from = 'åãàáäâẽèéëêìíïîõòóöôùúüûñçø·/_,:;';
  const to = 'aaaaaaeeeeeiiiiooooouuuunco------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export const getUserLogin = () => {
  if (localStorage.getItem('_user')) {
    const _user = JSON.parse(localStorage.getItem('_user'));
    const userRoleToken = ServerConfigs[process.env.NODE_ENV].tokenSecret;
    const bytes = CrytoJs.AES.decrypt(_user.RoleId, userRoleToken);
    if (bytes.sigBytes === 0) return null;
    const decode = JSON.parse(bytes.toString(CrytoJs.enc.Utf8));
    _user.RoleId = decode;
    return _user;
  }
  return null;
};

export const getUserRole = (roleId) => {
  switch (roleId) {
    case 2:
      return 'Admin';
    case 3:
      return 'Accountant';
    case 4:
      return 'Accountant Limit';
    case 5:
      return 'VN Accountant';
    case 6:
      return 'Recruitment';
    case 7:
      return 'Viet Nam Admin';
    case 14:
      return 'CEO';
    case 15:
      return 'HR Eco Finans AS';
    case 16:
      return 'HR Oryton';
    case 17:
      return 'Developer';
    case 18:
      return 'Admin Recruitment';
    default:
      return null;
  }
};

export const rebuildRouterUrl = (router) => {
  // const { match: { params: { company } } } = this.props;
  const {
    location: { pathname },
  } = window;
  if (pathname) {
    const alias = pathname.split('/')[1];
    if (alias) return router.replace(':company', alias);
  }
  return router;
};

// capitalize first letter of each word
export const capitalizeLetter = (str) => {
  if (!str) return '';

  const checkShiftSpace = (val) => {
    if (val.charAt(0) === '\n') {
      return val.charAt(0) + val.charAt(1).toUpperCase() + val.substr(2).toLowerCase();
    }
    return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
  };

  const result = str
    .replace(/\r/g, ' ')
    .split(' ')
    .map((val) => {
      return val.search(/^(AS|ASA|DA|ANS|AB)/gi) === -1 ? checkShiftSpace(val) : val;
    })
    .join(' ');
  return result;
};

export const getParameterByName = (name, url = decodeURIComponent(window.location.href)) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const formatKidMessage = (value) => {
  if (value) {
    return value.length < 140 ? value : value.substr(0, 140);
  }
  return '';
};

export const qsstringify = (data) => {
  return queryString.stringify(data);
};

export const qsparse = (data) => {
  return queryString.parse(data);
};

export const getAccessToken = () => {
  if (localStorage.getItem('_user')) {
    const _user = JSON.parse(localStorage.getItem('_user'));
    const { AccessToken } = _user;
    return AccessToken;
  }
  return null;
};

// temp: only allow show payment action on staging and dev not production
export const allowPaymentAction = () => {
  // const { host } = window.location;
  return true;
  // return host !== 'bilagsky.no';
};

export const kontrollsiffferMod11 = (value) => {
  const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  const kontonummerWithoutSpacesAndPeriods = value.replace(/[\s.]+/g, '');
  if (kontonummerWithoutSpacesAndPeriods.length !== 11) {
    return false;
  } else {
    if (kontonummerWithoutSpacesAndPeriods === '00000000000') return false;

    const sjekksiffer = parseInt(kontonummerWithoutSpacesAndPeriods.charAt(10), 10);
    const kontonummerUtenSjekksiffer = kontonummerWithoutSpacesAndPeriods.substring(0, 10);
    let sum = 0;
    for (let index = 0; index < 10; index++) {
      sum += parseInt(kontonummerUtenSjekksiffer.charAt(index), 10) * weights[index];
    }
    const remainder = sum % 11;
    return sjekksiffer === (remainder === 0 ? 0 : 11 - remainder);
  }
};

const regexDate = '^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$';
const regexNaUy = '^([0-9]{4}|[0-9]{2})[./-]([0]?[1-9]|[1][0-2])[./-]([0]?[1-9]|[1|2][0-9]|[3][0|1])$';

const mapDataExelToTable = (data) => {
  try {
    const columns = [];
    const rows = [];
    const objCheckLength = {};
    const regex = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
    Object.keys(data).map((key) => {
      if (key === '!margins' || key === '!rows' || key === '!ref') {
        return true;
      }
      const [num, text] = key.match(regex);
      if (!objCheckLength[num]) {
        objCheckLength[num] = num;
        columns.push(num);
      }
      if (!objCheckLength[text]) {
        objCheckLength[text] = text;
        rows.push(text);
      }
      return true;
    });

    const result = [];

    rows.map((r) => {
      const dataInRow = columns.map((c) => {
        const value = data[`${c}${r}`];
        return value?.t === 'd' ? timeHelper.formatDate(value.v, 'DD.MM.YYYY') : value?.w ?? value?.v ?? '';
      });
      const isValueRow = dataInRow.some(
        (d) => (typeof d === 'string' && d.match(regexDate)) || (typeof d === 'string' && d.match(regexNaUy))
      );
      result.push({ data: dataInRow, isHeader: !isValueRow });
      return true;
    });

    const columnDetects = columns.map((val, index) => {
      const isAmount = result.some((r) => {
        let value = r.data[index];
        if (typeof value === 'number') {
          value = `${value}`;
        }
        if (value?.includes(',') || value?.includes('.') || Number.isFinite(+value)) {
          const newVal = currencyHelper.formatMoney(value);
          if (
            newVal !== value.toString().replace(/ /g, '') ||
            (newVal === value && typeof Number(newVal.replace(/,/g, '.') === 'number') && !isNaN(Number(newVal.replace(/,/g, '.'))))
          ) {
            if (value.includes(',') || value.includes('.')) {
              return true;
            }
            if (+newVal?.replace(/ /g, '').replace(/,/g, '.') < 0) {
              return true;
            }
          } else {
            return false;
          }
        }
        return false;
      });
      return { val, isAmount };
    });

    return { result, columns, columnDetects };
  } catch (error) {
    return { result: [], columns: [], columnDetects: [] };
  }
};

const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

const eventResizer = (id, reset) => {
  // Query the element
  const resizer = document.getElementById(id);
  const leftSide = resizer.previousElementSibling;
  const rightSide = resizer.nextElementSibling;

  // The current position of mouse
  let x = 0;
  let y = 0;
  let leftWidth = 0;
  let rightWidth = 0;

  // Handle the mousedown event
  // that's triggered when user drags the resizer
  const mouseDownHandler = function (e) {
    // Get the current mouse position
    x = e.clientX;
    y = e.clientY;
    leftWidth = leftSide.getBoundingClientRect().width;
    rightWidth = rightSide.getBoundingClientRect().width;

    // Attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - x;

    const newLeftWidth = ((leftWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
    const newRightWidth = ((rightWidth - dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
    const rzRightWidth = newLeftWidth + newRightWidth > 99.75 ? newRightWidth - 0.8 : Math.floor(newRightWidth);
    if (newRightWidth > 23 && newLeftWidth > 23) {
      leftSide.style.width = `${newLeftWidth}%`;
      rightSide.style.width = `${rzRightWidth}%`;
    }

    resizer.style.cursor = 'col-resize';
    document.body.style.cursor = 'col-resize';

    leftSide.style.userSelect = 'none';
    leftSide.style.pointerEvents = 'none';

    rightSide.style.userSelect = 'none';
    rightSide.style.pointerEvents = 'none';
  };

  const mouseUpHandler = function () {
    resizer.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');

    leftSide.style.removeProperty('user-select');
    leftSide.style.removeProperty('pointer-events');

    rightSide.style.removeProperty('user-select');
    rightSide.style.removeProperty('pointer-events');

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };
  // Attach the handler
  if (reset) {
    resizer.removeEventListener('mousedown', mouseDownHandler);
  } else {
    resizer.addEventListener('mousedown', mouseDownHandler);
  }
};

const getWidthBoxRight = (left, center) => {
  const leftWidth = document?.getElementById(left)?.parentNode?.getBoundingClientRect()?.width;
  const rightWidth = (document?.getElementById(center)?.getBoundingClientRect()?.width + document?.getElementById(left)?.getBoundingClientRect()?.width);
  let newWidth = leftWidth - rightWidth;
  if (!Number.isInteger(newWidth)) newWidth = Math.floor(newWidth);
  return newWidth < 100 ? '100%' : newWidth;
};

const checkTextByType = (value, level = 1, split) => {
  let [d1, d2, d3, d4] = value.split('');
  const space = d2 && d2 === split ? d2 : d3 ?? 0;
  switch (level) {
    case 1:
      if (+d1 === 0) {
        if (+d2 === 0) {
          return false;
        }
      }
      if ((d1 && !Number.isFinite(+d1)) || (d2 && d2 !== space && !Number.isFinite(+d2)) || (space && space !== split)) {
        return false;
      }
      break;
    case 2:
      if (+d1 === 0) {
        if (+d2 === 0) {
          return false;
        }
      }
      if ((space === d2 && +d1 <= 0) || (space === d2 && +d1 > 9)) {
        return false;
      }
      if (
        (d1 && !Number.isFinite(+d1)) ||
        (d2 && d2 !== space && !Number.isFinite(+d2)) ||
        (space && space !== split) ||
        (d1 && d2 && +d1.concat(d2) > 12)
      ) {
        return false;
      }
      break;
    case 3:
      if (
        (d1 && !Number.isFinite(+d1)) ||
        (d2 && !Number.isFinite(+d2)) ||
        (d3 && !Number.isFinite(+d3)) ||
        (d4 && !Number.isFinite(+d4)) ||
        value.length > 4
      ) {
        return false;
      }
      break;
    default:
      return true;
  }
  return true;
};

const checkInFormat = (value, split = '.') => {
  let level = 1;
  while (value !== '') {
    let [d1, d2, d3] = value.split('');
    const space = d2 && d2 === split ? d2 : d3 ?? 0;
    if (!checkTextByType(value, level, split)) {
      return { result: false, level };
    }
    value = level === 3 ? '' : value.slice(space === d2 ? 2 : 3, value.length);
    level++;
  }
  return { result: true, level: level - 1 };
};

const onChangeCommentEditor = (editor, setState, isReadonly = false) => {
  if (!isReadonly) {
    setState(editor.getData());
  }
};

const imageTypes = ['jpg', 'png', 'jpeg'];

const exel = ['xlsx', 'xls', 'csv'];

function getFormValues(form) {
  const formData = new FormData(form);
  let values = {};
  for (const [inputName, val] of formData.entries()) {
    if (inputName.endsWith('[]')) {
      values[inputName] = values[inputName] ? [...values[inputName], val] : [val];
    } else {
      values[inputName] = val;
    }
  }
  return values;
}

export const SweetServerError = () => {
  Swal.fire({
    title: 'Oops...',
    text: 'Server Error....',
    icon: 'error',
  });
};

export function useScrollDirection() {
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);
  return goingUp ? 'up' : 'down';
}

export const truncateText = (text, maxLength) => {
  if (!text) return '';
  // If the text is shorter than or equal to the maximum length, return it as it is
  if (text.length <= maxLength) return text;
  // Otherwise, find the last space before the maximum length minus 3 using a regular expression
  let lastSpace = text.slice(0, maxLength - 3).lastIndexOf(" ");
  // If there is no space, return the text sliced from the beginning to the maximum length minus 3 and add three dots
  if (lastSpace === -1) {
    return `${text.slice(0, maxLength - 3)}...`;
  }
  // Otherwise, return the text sliced from the beginning to the last space and add three dots
  return `${text.slice(0, lastSpace)}...`;
};

export const isValidBic = bic => {
  //NO1510804651581
  return ibantools.isValidBIC(bic);
}

export const clearFormatBankNumber = (input = '') => input.replace(/\./g, '');


export {
  setCookie,
  getCookie,
  deleteCookie,
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  loadNewBody,
  revertBody,
  checkCompanyExist,
  getCurrentCompany,
  renderHTML,
  formatDatabase,
  bankNumber,
  dateFormat,
  formatView,
  capitalize,
  capitalizeSpace,
  getJsonFromUrl,
  mapDataExelToTable,
  regexDate,
  regexNaUy,
  formatCommon,
  blobToBase64,
  eventResizer,
  getWidthBoxRight,
  checkInFormat,
  onChangeCommentEditor,
  imageTypes,
  exel,
  getFormValues,
  isHtml,
};
