/*
  BankService table
*/

import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  buyRemittance: async (params) => {
    //  id, status, accountName,  accountNumber
    try {
      const data = await BaseServices.postDataToken(api.companyBankService.buyRemittance, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addUpdate: async (params) => {
    //  id, status, accountName,  accountNumber
    try {
      const data = await BaseServices.postDataToken(api.companyBankService.addUpdate, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchAllCompanyBankInfoByCompanyId: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companyBank.fetchAllCompanyBankInfoByCompanyId}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getSettingBankAccount: async (params) => {
    try {
      const paramString = httpHelper.serialize(params);
      const url = `${api.companyBank.getSettingBankAccount}?${paramString}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getBankBalance: async (params = {}) => {
    try {
      const { url } = params;
      delete params.url;
      const paramString = httpHelper.serialize(params);
      const requestUrl = `${url}?${paramString}`;
      const data = await BaseServices.getBankDataToken(requestUrl);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  setDefaultAccountNumber: async (params) => {
    //  id, accountNumber
    try {
      const data = await BaseServices.postDataToken(api.companyBank.setDefaultAccountNumber, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  deleteCompanyBank: async (params) => {
    try {
      const { id } = params;
      const url = api.companyBank.deleteCompanyBank.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getDefaultAccountNumber: async (params) => {
    //  id, accountNumber
    try {
      const query = httpHelper.serialize(params);
      const url = api.companyBank.getDefaultAccountNumber + '?' + query;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  sortingBankAccount: async (body) => {
    try {
      const url = api.companyBank.sortingBankAccount;
      const data = await BaseServices.putDataToken(url, body);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getBankVerifyPayment: async (params = {}) => {
    try {
      const { companyId } = params;
      const query = httpHelper.serialize(params);
      const url = api.companyBankV2.getBankVerifyPayment.replace(':companyId', companyId) + '?' + query;     
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getServiceDates: async id => {
    try {
      const url = api.companyBank.getServiceDates.replace(':id', id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
};
