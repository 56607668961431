import moment from 'moment-timezone';
import { getTimeZone } from '../utils/Utils';


// English locale
moment.updateLocale('en', {
  months: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  monthsShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ],
  weekdays: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ],
  weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
});

// Vietnamese locale
moment.updateLocale('vi', {
  months: [
    'Tháng Một', 'Tháng Hai', 'Tháng Ba', 'Tháng Tư', 'Tháng Năm', 'Tháng Sáu',
    'Tháng Bảy', 'Tháng Tám', 'Tháng Chín', 'Tháng Mười', 'Tháng Mười Một', 'Tháng Mười Hai'
  ],
  monthsShort: [
    'Thg 1', 'Thg 2', 'Thg 3', 'Thg 4', 'Thg 5', 'Thg 6',
    'Thg 7', 'Thg 8', 'Thg 9', 'Thg 10', 'Thg 11', 'Thg 12'
  ],
  weekdays: [
    'Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'
  ],
  weekdaysShort: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
});

// Norwegian locale
moment.updateLocale('no', {
  months: [
    'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
  ],
  monthsShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun',
    'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'
  ],
  weekdays: [
    'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'
  ],
  weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
});

const timezone = getTimeZone();

export default {
  handleChange: (value) => {
    return value.replace(/[a-zA-Z]/g, '');
  },
  onBlur: (value, callback = null) => {
    value = value.trim();
    value = value.replace(/ /g, '');
    if (value.length === 8) {
      const d = value.substr(0, 2);
      const m = value.substr(2, 2);
      const y = value.substr(4, 4);
      return `${d}.${m}.${y}`;
    }
    const dirs = (value.match(/\//g) || []).length;
    const dashes = (value.match(/-/g) || []).length;
    if (dirs === 2) value = value.replace(/\//g, '.');
    if (dashes === 2) value = value.replace(/-/g, '.');
    if (callback) {
      callback();
    }
    return value;
  },
  format(time, tz, monthStrings) {
    try {
      const dateTime = new Date(time);
      dateTime.setHours(dateTime.getHours() + tz);
      return `${dateTime.getDate()} ${monthStrings[dateTime.getMonth() + 1]}`;
    } catch (e) {
      return '';
    }
  },
  getDate(time) {
    const dateTime = new Date(time);
    return `${dateTime.getMonth() < 9 ? '0' + (dateTime.getMonth() + 1) : dateTime.getMonth() + 1}/${dateTime.getDate() < 10 ? '0' + dateTime.getDate() : dateTime.getDate()
      }/${dateTime.getFullYear()}`;
  },
  getMonthDateRange(year, month) {
    // month: 0 = Jan
    const date = new Date(year, month - 1);
    const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date).endOf('months').format('YYYY-MM-DD');
    // make sure to call toDate() for plain JavaScript date type
    return {
      startDate,
      endDate,
    };
  },
  getYearRange(year) {
    // month: 0 = Jan
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;
    // make sure to call toDate() for plain JavaScript date type
    return {
      startDate,
      endDate,
    };
  },
  formatDate(time, type = 'DD.MM.YYYY') {
    if (time && moment().isValid(time)) {
      // if time null or underfine => today
      if (timezone === 'Asia/Singapore') {
        return moment(time).tz(timezone).format(type);
      }
      // prod: timezone = Europe/Amsterdam use +2
      return moment(time).tz(timezone).utcOffset('+0200').format(type);
    }
    return null;
  },
  formatDateTime(time, type = 'DD.MM.YYYY HH:mm:ss') {
    return moment(time).tz(timezone).format(type);
  },
  formatLocalDateTime(time, type = 'DD.MM.YYYY HH:mm:ss') {
    return moment.utc(time).local().format(type);
  },
  formatDB(date, dateFormat = 'DD.MM.YYYY', dateDB = 'YYYY-MM-DD') {
    return moment(date, dateFormat).format(dateDB);
  },
  formatDateTZ(date, dateFormat = 'DD.MM.YYYY') {
    // use for create view same format with database dat: 2021-10-10T1600:00:00Z
    return moment.tz(date, dateFormat, timezone).utc().format();
  },
  autoFormat(dateInput) {
    const value = dateInput;
    const regexp = /^[0-9]+$/;
    let date = 'Invalid date';
    if (regexp.test(value) && value.length > 0) {
      let day = null;
      let month = null;
      let year = new Date().getFullYear();
      switch (value.length) {
        case 1:
          day = value;
          month = value;
          year = year.toString().substr(2, 2);
          break;
        case 2:
          day = value.substr(0, 1);
          month = value.substr(1, 2);
          year = year.toString().substr(2, 2);
          break;
        case 3:
          day = value.substr(0, 2);
          month = value.substr(2, 1);
          year = year.toString().substr(2, 2);
          break;
        case 4:
          day = value.substr(0, 2);
          month = value.substr(2, 2);
          year = year.toString().substr(2, 2);
          break;
        case 6:
          day = value.substr(0, 2);
          month = value.substr(2, 2);
          year = value.substr(4, 2);
          break;
        case 8:
          day = value.substr(0, 2);
          month = value.substr(2, 2);
          year = value.substr(6, 2);
          break;
        default:
          break;
      }
      date = `${day}-${month}-${year}`;
      date = moment(date, 'DD-MM-YY').format('DD.MM.YYYY');
    }
    return date;
  },
  validateDate(date) {
    return moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
  },
  isFutureDate: (date) => {
    // date input format = 'YYYY-MM-DD'
    const today = moment().format('YYYY-MM-DD');
    return moment(date, 'YYYY-MM-DD').isAfter(today, 'day');
  },
  today: () => {
    return new Date(moment().format('YYYY/MM/DD'));
  },
  currentDate: (format = 'DD.MM.YYYY') => {
    return moment().format(format);
  },
  currentWeekRange: () => {
    const today = moment();
    const from = today.startOf('week').format('YYYY/MM/DD');
    const to = today.endOf('week').format('YYYY/MM/DD');
    return {
      from: new Date(from),
      to: new Date(to),
    };
  },
  currentMonthRange: () => {
    const today = moment();
    const start = today.startOf('month').format('YYYY/MM/DD');
    const end = today.endOf('month').format('YYYY/MM/DD');
    return {
      from: new Date(start),
      to: new Date(end),
    };
  },
  currentYearRange: () => {
    const today = moment();
    const from = today.startOf('year').format('YYYY/MM/DD');
    const to = today.endOf('year').format('YYYY/MM/DD');
    return {
      from: new Date(from),
      to: new Date(to),
    };
  },
  monthAgo: (format = 'YYYY/MM/DD') => {
    return new Date(moment().subtract(1, 'months').format(format));
  },
  yearAgo: (format = 'YYYY/MM/DD') => {
    return moment().subtract(12, 'months').format(format);
  },
  yearNext: (format = 'YYYY/MM/DD') => {
    return moment().add(12, 'months').format(format);
  },
  formatDatePicker: (date) => {
    return moment(date).format('YYYY/MM/DD');
  },
  isPastDate: (date) => {
    // date input format = 'YYYY-MM-DD'
    // return true if date1 after date 2
    const today = moment().format('YYYY-MM-DD');
    const compareDate = moment(date).format();
    const status = moment(compareDate).isBefore(today, 'day');
    return status;
  },
  formatDateNoTimeZone(time, type = 'DD.MM.YYYY') {
    // if time null or underfine => today
    return moment(time, 'YYYY-MM-DD').format(type);
  },
  isToday(date) {
    const today = new Date();
    const compareDate = new Date(date);
    return (
      compareDate.getDate() == today.getDate() &&
      compareDate.getMonth() == today.getMonth() &&
      compareDate.getFullYear() == today.getFullYear()
    );
  },
  formatUtcDate: (time, format = 'YYYY-MM-DD') => {
    const d = new Date(time);
    const userTimezoneOffset = d.getTimezoneOffset() * 6000;
    const rawDate = new Date(d.getTime() - userTimezoneOffset);
    return moment(rawDate).format(format);
  },
  checkExpiredDate: (expiredDate) => {
    const d = new Date().toISOString();
    const expired = new Date(expiredDate);
    const date = new Date(d);
    const dif = date - expired;
    return dif >= 0; // true, if dif < 0 false ( not expired )
  },
  compareDate: (firstDate, secondDate) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const dif = date1 - date2;
    return dif >= 0; // true, if dif < 0 false ( date1 < date2 )
  },
  getPrevMonthText() {
    const lang = localStorage.getItem('lang') || 'en';
    return moment().subtract(1, 'month').locale(lang).format('MMMM'); // 'MMMM' format specifier gives the full name of the month
  }
};
