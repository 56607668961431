const PrefixUrl = '/:company';

const PaymentList = PrefixUrl + '/payments/payment-list';

const ErrorRoutes = {
  Notfound: '/404',
};
const RouterNoPrefix = {
  Video: '/video',
  VideoAndroid: '/video/android',
  VideoPaymentDNBAndroid: '/video/pay-dnb-android',
  VideoPaymentSP1Android: '/video/pay-sp1-android',
  EditDescriptionTask: '/task-description-webview',
  EditCommentTask: '/task-edit-comment-webview',
  CreateCommentTask: '/task-create-comment-webview',
  ConsentSignant: '/consent/sca-callback',
  ZtlPaymentSign: '/ztl-payment/sca-callback',
  OnboardingCallback: '/onboarding/callback',
  Invoice: '/video/invoice-web',
};
const RoutesUrl = {
  Dashboard: PrefixUrl + '/',
  Voucher: PrefixUrl + '/voucher',
  Bank: PrefixUrl + '/bank',
  BankManagement: PrefixUrl + '/bank-management',
  BankImport: PrefixUrl + '/bank/import-document',
  PaymentList,
  PaymentInternationalList: PaymentList + '/international',
  InternalTransfer: PrefixUrl + '/payments/internal-transfer',
  PaymentList_AssignUsers: PrefixUrl + '/payments/assign-users',
  ConnectMissing: PrefixUrl + '/connect-missing',
  Setting: PrefixUrl + '/setting',
  SettingZTLConsent: PrefixUrl + '/setting/ztl-consent/:id/renew',
  Inbox: PrefixUrl + '/inbox',
  InboxDetail: PrefixUrl + '/inbox/:uuid',
  AdminImportAuto: PrefixUrl + '/admin-import-auto',
  AdminAutoImport: PrefixUrl + '/admin-auto-import',
  AdminBankDivision: PrefixUrl + '/admin-bank-division',
  AdminImportManual: PrefixUrl + '/admin-import-manual',
  AdminEHF: PrefixUrl + '/admin-ehf',
  AdminVoucher: PrefixUrl + '/admin-voucher',
  AdminEmail: PrefixUrl + '/admin-email',
  AdminMailgun: PrefixUrl + '/admin-maingun',
  AdminTask: PrefixUrl + '/task-overview',
  Unauthorized: PrefixUrl + '/unauthorized',
  AiSettingGlobal: PrefixUrl + '/ai-setting/ai-global',
  AiSettingLocal: PrefixUrl + '/ai-setting/ai-local',
  AdminSettingAiVoucher: PrefixUrl + '/admin-setting/ai-voucher',
  AdminSettingAiVoucherData: PrefixUrl + '/admin-setting/ai-voucher-data',
  BankVerify: PrefixUrl + '/bank-verify',
  SignCancel: PrefixUrl + '/sign-cancel',
  SignNext: PrefixUrl + '/sign-next',
  Task: PrefixUrl + '/task',
  TaskTag: PrefixUrl + '/task/label',
  TaskTemplate: PrefixUrl + '/task/template',
  TaskTemplateGlobal: PrefixUrl + '/task/template-global',
  Statistics: PrefixUrl + '/statistics',
  StatisticPayment: PrefixUrl + '/statistic/payment',
  StatisticVoucher: PrefixUrl + '/statistic/voucher',
  StatisticBank: PrefixUrl + '/statistic/bank',
  StatisticMVA: PrefixUrl + '/statistic/mva',
  StatisticZtl: PrefixUrl + '/statistic/ztl',
};

export { ErrorRoutes, RoutesUrl, PrefixUrl, RouterNoPrefix };
