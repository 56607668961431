import CrytoJs from 'crypto-js';
import Swal from 'sweetalert2';
import { getUserLogin } from '../utils/Utils';
import authenticationServices from './authenticationServices';
import AuthenticationServices from './authenticationServices';
import * as ServerConfigs from '../configs/server.configs';
import i18n from '../i18n';
import { fireNotification } from '../utils/fireNotification';

// import { webConfig } from '../configs/system.configs';
require('es6-promise').polyfill();
require('isomorphic-fetch');

const _loginUser = getUserLogin();

const getLanguage = () => {
  return localStorage.getItem('i18nextLng') || 'en';
};

const _alertFailedToFetch = async () => {
  const message = i18n.t('system:message:failedToFetch');
  return fireNotification({ message, type: 0 });
}

const _alertExpired = async () => {
  if (_loginUser) {
    const oktext = i18n.t('system:ok');
    const alertText = i18n.t('system:alertExpired:content').replace('{OK}', oktext);
    const _confirmRenew = async () => {
      const { RefreshToken: refreshToken } = _loginUser;
      // refresh token data. If exist show alert expired and renew
      const responseToken = await authenticationServices.refreshToken(refreshToken);
      const { isSuccess } = responseToken;
      if (isSuccess) {
        let _user = { ..._loginUser };
        const roleId = JSON.stringify(_user.RoleId);
        const userRoleToken = ServerConfigs[process.env.NODE_ENV].tokenSecret;
        _user.RoleId = CrytoJs.AES.encrypt(roleId, userRoleToken).toString();
        _user.AccessToken = responseToken.data.token;
        _user = JSON.stringify(_user);
        localStorage.setItem('_user', _user);
        window.location.reload();
      }
    }
    // auto logout after 1 minutes
    setTimeout(() => {
      // logout
      authenticationServices.logout();
    }, 1000 * 60);
    Swal.fire({
      text: alertText,
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonText: oktext,
    }).then(async (result) => {
      if (result.value) {
        await _confirmRenew();
      }
    });
  } else {
    // logout
    authenticationServices.logout();
  }
};

const _invalidToken = async (response) => {
  if (response.status === 401) {
    const resJson = await response.json();
    // token expired => confirm renew
    if (!resJson.success && resJson.message === 'Token Expired') {
      return true;
    }
    // invalid token => logout
    const invalidToken = resJson.error_type === 'TOKEN_EXPIRED' || resJson.message == 'Invalid Token';
    if (!resJson.success && invalidToken) {
      return authenticationServices.logout();
    }
  }
  return false
}

export default {
  // BILAGSKY
  getData: async ({
    url,
    headers = {
      'Content-Type': 'application/json',
      Lang: getLanguage(),
    },
  }) => {
    try {
      return await fetch(url, {
        headers,
        method: 'GET',
        mode: 'cors',
        // cache: 'no-cache',
        // credentials: 'same-origin',
        // redirect: 'follow', // manual, *follow, error
        // referrer: 'no-referrer' // no-referrer, *client
      });
    } catch (error) {
      throw error;
    }
  },
  postData: async ({
    url,
    data,
    headers = {
      'Content-Type': 'application/json',
      Lang: getLanguage(),
    },
  }) => {
    try {
      return await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
      });
    } catch (error) {
      throw error;
    }
  },
  postDataToken: async (url, data) => {
    try {
      const token = AuthenticationServices.getAuthenticateToken();
      const response = fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
      });
      if (await _invalidToken(response)) {
        return await _alertExpired();
      }
      return await response;
    } catch (error) {
      throw error;
    }
  },
  putData: async (url, data) => {
    try {
      return await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Lang: getLanguage(),
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match 'Content-Type' header
      });
    } catch (error) {
      throw error;
    }
  },
  putDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const response = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data),
    });
    if (await _invalidToken(response)) {
      return await _alertExpired();
    }
    return response;
  },
  putDataTokenForm: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      if (Array.isArray(data[name])) {
        data[name].map((v) => {
          formData.append(`${name}[]`, v);
        });
        continue;
      }
      if (typeof data[name] === 'object' && !Array.isArray(data[name]) && !(data[name] instanceof Date) && !(data[name] instanceof File)) {
        Object.keys(data[name]).map((key) => formData.append(`${name}[${key}]`, data[name][key]));
        continue;
      }
      formData.append(name, data[name]);
    }
    const response = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData,
    });
    if (await _invalidToken(response)) {
      return await _alertExpired();
    }
    return response;
  },
  postDataTokenForm: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      if (Array.isArray(data[name])) {
        data[name].map((v) => {
          formData.append(`${name}[]`, v);
        });
        continue;
      }
      if (typeof data[name] === 'object' && !Array.isArray(data[name]) && !(data[name] instanceof Date) && !(data[name] instanceof File)) {
        Object.keys(data[name]).map((key) => formData.append(`${name}[${key}]`, data[name][key]));
        continue;
      }
      formData.append(name, data[name]);
    }
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData,
    });
    if (await _invalidToken(response)) {
      return await _alertExpired();
    }
    return response;
  },
  deleteDataToken: async (url) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    });
  },
  getDataToken: async (url) => {
    try {
      const token = AuthenticationServices.getAuthenticateToken();
      const response = await fetch(url, {
        method: 'GET',
        // mode: 'cors',
        // cache: 'no-cache',
        // credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          Lang: getLanguage(),
        },
        redirect: 'follow',
        referrer: 'no-referrer',
      });
      if (await _invalidToken(response)) {
        return await _alertExpired();
      }
      return response;
    } catch (error) {
      console.log(294, error.toString());
      if (error?.toString() === 'TypeError: Failed to fetch') {
        return _alertFailedToFetch();
      }
      throw error;
    }
  },
  // DNB
  getBankDataToken: async (url) => {
    const token = AuthenticationServices.getBankToken();
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        Lang: getLanguage(),
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    });
  },
  // EHF
  getEHFDataToken: async (url) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        Lang: getLanguage(),
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    });
  },
  // ORYTON API
  getOrytonDataToken: async (url) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'GET',
      // mode: 'cors',
      // cache: 'no-cache',
      // credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        Lang: getLanguage(),
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    });
  },
  postOrytonDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data),
    });
  },
  putOrytonDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'PUT',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data),
    });
  },
  deleteOrytonDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data),
    });
  },
};
